import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ABBR from '../settings/abbr.json';
import { abbr } from '../helpers';

const combine = (...args) => {
  const arr = Array.isArray(args[0]) ? args[0] : args;
  return Object.assign({}, ...arr);
};

{
  const arr = Object.values(ABBR);
  const deduped = Array.from(new Set(arr));
  if (deduped.length !== arr.length) {
    // eslint-disable-next-line no-console
    console.error('Abbreviation collision found');
  }
}

export const flattenService = service =>
  Object.entries(service).reduce((acc, [property, value]) => {
    if (Array.isArray(value) || typeof value !== 'object') {
      return { ...acc, [property]: value };
    }

    if (typeof value === 'object') {
      return {
        ...acc,
        ...Object.fromEntries(
          Object.entries(value).map(([k, v]) => [
            [[property, k].map(abbr).join('.'), v],
            [abbr(k), v],
          ]).flat(),
        ),
      };
    }

    return acc;
  }, {});

export const useServiceProviders = () => {
  const { wrapper: { nodes } } = useStaticQuery(graphql`
    query {
      wrapper: allFile(filter: {
        sourceInstanceName: {eq: "data-file"}
        relativeDirectory: {eq: "platforms"}
      }) {
        nodes {
          internal { content }
          slug: name
        }
      }
    }
  `);

  return React.useMemo(() => nodes.reduce(
    (acc, { internal: { content } = {}, ...rest }) => {
      try {
        return [...acc, { ...rest, ...JSON.parse(content) }];
      } catch (err) {
        console.error(err); // eslint-disable-line no-console
        return acc;
      }
    },
    [],
  ), [nodes]);
};

export const useServices = () => {
  const serviceProviders = useServiceProviders();

  return React.useMemo(() => {
    const items = [];

    serviceProviders.forEach(({
      Services = [],
      'Provider services overall summary': Summaries = [],
      name: provider,
      ...generic
    }) => {
      /**
       * Add Service Provider summary as (meta)service
       */
      Summaries.forEach((Summary, childIndex) => items.push(combine(
        { origin: 'summary', provider, childIndex },
        generic,
        flattenService(Summary),
      )));

      /**
       * Add each Service Provider service
       */
      Services.forEach((Service, childIndex) => items.push(combine(
        { origin: 'service', provider, childIndex },
        generic,
        flattenService(Service),
      )));
    });

    return items;
  }, [serviceProviders]);
};

export default useServices;
