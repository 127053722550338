import { Box, Typography } from '@material-ui/core';
import React from 'react';

import Layout from '../components/Layout';
import useServices from '../hooks/useServices';

const Debug = () => {
  const services = useServices();

  return (
    <Layout>
      {services.map(service => {
        const { slug, name, parent, childIndex } = service;

        return (
          <Box key={`${slug}${name}${parent}${childIndex}`} style={{ marginTop: '1rem' }}>
            <Typography variant="h3">
              {name || 'Summary'}{Boolean(parent) && ` (${parent})`}
            </Typography>

            <Typography variant="body1">
              {['institute', 'contactName', 'location'].map(
                key => (
                  Object.prototype.hasOwnProperty.call(service, key)
                    ? service[key]
                    : null
                ),
              ).filter(Boolean).join(', ')}
            </Typography>

            <pre style={{ whiteSpace: 'pre-wrap', maxHeight: 200, overflow: 'auto', border: '1px dotted silver' }}>
              {JSON.stringify(service, null, 2)}
            </pre>
          </Box>
        );
      })}
    </Layout>
  );
};

export default Debug;
